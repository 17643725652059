import React from 'react'
import axios from 'axios'

import { maintainerURL } from '../../api/urls';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles((theme) => ({
  main: {
    marginTop: theme.spacing(8),
  },
  paper: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }
}));


function ContactCard(props){
  const { contact } = {...props}
  const classes = useStyles();

  return (
    <div className={classes.paper}>
      {
        contact === null ? <></> : (
          <>
            <Typography component="h1" variant="h5">
              {contact.last_name}, {contact.first_name}
            </Typography>
            <Typography variant="caption" component="p">
              Tel 1: {contact.phone_number_1}
            </Typography>
            <Typography variant="caption" component="p">
              Tel 2: {contact.phone_number_2}
            </Typography>
            <Typography variant="caption" component="p">
              Email: {contact.email}
            </Typography>
          </>
        )
      }
    </div>
  )
}


export default function Contacts() {
  const classes = useStyles();
  const [contacts, setContacts] = React.useState([]);

  const loadMaintainer = async () => {
    const response = await axios.get(maintainerURL)
    setContacts(response.data)
  }

  React.useEffect(() => {
    loadMaintainer()
  }, [])

  return (
    <div className={classes.main}>
      {
        contacts.map((contact, index) =>
          <ContactCard
            key={index}
            contact={contact}
          />
        )
      }
    </div>
  )
}
