import * as actionTypes from './actionTypes';


export const openSnackbar = (info) => {
  return dispatch => {
    dispatch(closeSnackbar());
    dispatch(handleOpen(info));
  }
}

const handleOpen = (info) => {
  return {
    type: actionTypes.OPEN_SNACKBAR,
    info: info
  }
}

export const closeSnackbar = () => {
  return {
      type: actionTypes.CLOSE_SNACKBAR
  }
}
