import React from 'react';

import SimpleSnackbar from './../snackbar';

import Nav from './nav.jsx';
import Footer from './footer.jsx';
import Thumbnail from './thumbnail.jsx'

import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';


export default function Base(props){
  return (
    <>
      <CssBaseline />
      <Nav />
      <Thumbnail style={{marginTop: '500px'}}/>
      <main style={{marginTop: '40px'}}>
        <Container>
          {props.children}
        </Container>
        <SimpleSnackbar />
      </main>
      <Footer />
    </>
  )
}