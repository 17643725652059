import React from 'react'
import axios from 'axios'
import { connect } from 'react-redux'

import {editEmailURL } from './../../api/urls';
import { defaultAuthHeader } from './../../api/headers';
import { openSnackbar } from './../../store/actions/snackbar';


import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';


const setInitialProfileInfo = (user) => {
  return {
    email: user.email,
    password: ""
  }
}

export const EmailForm = (props) => {
  const [profileInfo, setProfileInfo] = React.useState(null)
  const [error, setError] = React.useState("")

  React.useEffect(() => {
    setProfileInfo(setInitialProfileInfo(props.user))
  }, []);

  const handleUpdate = (e) => {
    setProfileInfo({
      ...profileInfo,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const data = {
      email: profileInfo.email,
      password: profileInfo.password
    }

    try {
      const assignmentTable = await axios.put(editEmailURL, data, defaultAuthHeader)
      setError("")
      props.openSnackbar("Die Email-Adresse wurde aktualisiert.");
    }
    catch (err) {
      setError("Falsche Passworteingabe")
    }
    setProfileInfo({
      email: profileInfo.email,
      password: ""
    });
  }


  if (profileInfo === null) return <></>

  return (
    <form>
      <TextField
        onChange={handleUpdate}
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Adresse"
        name="email"
        value={profileInfo.email}
      />
      <TextField
        onChange={handleUpdate}
        variant="outlined"
        margin="normal"
        error={error !== ""}
        required
        fullWidth
        type="password"
        id="password"
        label="Aktuelles Passwort"
        name="password"
        helperText={error}
        value={profileInfo.password}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        onClick={handleSubmit}
      >
        Speichern
      </Button>
    </form>
  )
}

const mapStateToProps = (state) => ({
  user: state.auth.user
})

const mapDispatchToProps = {
  openSnackbar: openSnackbar
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailForm)
