import React from 'react';

import BaseAssignment from './dialogs/baseAssignment';
import ContactSection from './dialogs/contactSection';
import Log from './dialogs/logs';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';



const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AssignmentDialog(props) {
  const classes = useStyles();
  const { handleClose, open, assignmentDialog, handleNewAssignment, reloadDataOnLogUpdate } = { ...props };
  const assignment = assignmentDialog;

  const getTitle = () => {
    if (assignment === null) return "Neuer Auftrag"
    return `Auftragsdetails ${assignment.no}`;
  }

  return (
    <div>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {getTitle()}
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <BaseAssignment
              assignment={assignment}
              handleNewAssignment={handleNewAssignment}
            />
            {
              assignment === null ? <></> : <ContactSection
                contactCustomer={assignment.customer_contact}
                contactAdmin={assignment.admin_contact}
              />
            }
          </Grid>
          <Grid item xs={12} sm={4}>
            <Log
              assignment={assignment}
              reloadDataOnLogUpdate={reloadDataOnLogUpdate}
            />
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}
