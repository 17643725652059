import React from 'react'
import axios from 'axios'
import { connect } from 'react-redux'

import {resetPasswordURL } from './../../api/urls';
import { openSnackbar } from './../../store/actions/snackbar';


import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';


const initialEmail = {
  email: ""
}

export const ResetPasswordForm = (props) => {
  const [emailForm, setEmailForm] = React.useState(initialEmail)

  const handleUpdate = (e) => {
    setEmailForm({
      ...emailForm,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const assignmentTable = await axios.put(resetPasswordURL, emailForm)
    setEmailForm(initialEmail)
    props.openSnackbar("Das Passwort wurde für die angegebene Email Adresse zurückgesetzt. Bitte prüfen Sie ihr Email Postfach.");
  }

  return (
    <>
      <Typography component="h1" variant="h5">
        Passwort vergessen
      </Typography>
      <form>
        <TextField
          onChange={handleUpdate}
          variant="outlined"
          margin="normal"
          fullWidth
          id="email"
          label="Email-Adresse"
          name="email"
          value={emailForm.email}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          Absenden
        </Button>
      </form>
    </>
  )
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
  openSnackbar: openSnackbar
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordForm)