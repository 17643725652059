import React from 'react';

import { connect } from 'react-redux';

import { fade, makeStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));


export function CreateNew(props) {
  const classes = useStyles();

  var dropdownChoices = []
  if (props.user.company.is_admin || props.user.rights === "a"){
    dropdownChoices = [
      {
        value: "ownAssignments",
        text: "Eigene nicht beendete Aufträge"
      },
      {
        value: "newAssignments",
        text: "Aufträge ohne Zuweisung"
      },
      {
        value: "inProgress",
        text: "In Bearbeitung"
      }
    ]
  }
  else {
    dropdownChoices = [
      {
        value: "newAssignments",
        text: "Aufträge ohne Zuweisung"
      },
      {
        value: "inProgress",
        text: "In Bearbeitung"
      }
    ]
  }



  return (
    <div>
      <Divider/>
        <Box display="flex" p={1} bgcolor="background.paper">
          <Box p={1} flexGrow={1}>
            <FormControl variant="outlined">
              <InputLabel id="demo-simple-select-outlined-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={props.filterType}
                onChange={props.handleChangeFilterType}
                label="Status"
              >
                {
                  dropdownChoices.map((choice, index) =>
                    <MenuItem key={index} value={choice.value}>{choice.text}</MenuItem>
                  )
                } 
              </Select>
            </FormControl>
          </Box>
          <Box p={1}>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Suche ..."
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
                onChange={props.updateSearch}
              />
            </div>
          </Box>
          <Box p={1}>
            <Button
              variant="contained"
              color="primary"
              onClick={props.handleAssignmentDialogOpen}
            >
              Neuer Auftrag
            </Button>
          </Box>
        </Box>
      <Divider/>
    </div>
  )
}

const mapStateToProps = (state) => ({
  user: state.auth.user
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(CreateNew);