import React from 'react';

import NewAssignmentsTable from './tables/newAssignments.jsx';
import InProgressTable from './tables/inProgress.jsx';
import OwnAssignmentTable from './tables/ownAssignments.jsx';
import SearchedAssignmentsTable from './tables/search.jsx';

export default function DataTable(props) {
  const tables = {
    inProgress: <InProgressTable assignments={props.assignments} handleOpenDetails={props.handleOpenDetails}/>,
    newAssignments: <NewAssignmentsTable assignments={props.assignments} handleOpenDetails={props.handleOpenDetails} />,
    ownAssignments: <OwnAssignmentTable assignments={props.assignments} handleOpenDetails={props.handleOpenDetails} />,
    searchedAssignments: <SearchedAssignmentsTable assignments={props.assignments} handleOpenDetails={props.handleOpenDetails} />
  }
  return (
    <>{tables[props.filterType]}</>
  )
}
