import React from 'react'

import { MEDIA_URL } from './../../../api/urls.js';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DescriptionIcon from '@material-ui/icons/Description';


function FileName(props){
  var { path } = props;
  var pathSplit = path.split("/")
  return <span>{pathSplit[pathSplit.length - 1]}</span>
}


export default function ListFiles(props) {
  const { files } = props;

  const openAttachement = (path) => {
    window.open(`${MEDIA_URL}${path}`)
  }

  return (
    <List
      component="nav"
      aria-label="Attachements"
    >
    {
      files.map((file, index) =>
        <ListItem
          button
          key={index}
          onClick={() => openAttachement(file.file_path)}
        >
          <ListItemIcon>
            <DescriptionIcon />
          </ListItemIcon>
          <ListItemText
            primary={<FileName path={file.file_path}/>}
          />
        </ListItem>
      )
    } 
    </List>
  )
}
