import { combineReducers } from 'redux';
import auth  from './auth.js';
import snackbar from './snackbar.js';

let reduce = combineReducers({
  auth: auth,
  snackbar: snackbar
})

export default reduce;
