import React from 'react'
import axios from 'axios'
import { connect } from 'react-redux'

import { editGeneralInfoURL } from './../../api/urls';
import { defaultAuthHeader } from './../../api/headers';
import { openSnackbar } from './../../store/actions/snackbar';


import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';


const setInitialProfileInfo = (user) => {
  return {
    first_name: user.first_name,
    last_name: user.last_name,
    phone_number_1: user.phone_number_1,
    phone_number_2: user.phone_number_2
  }
}

export const GeneralInfoForm = (props) => {
  const [profileInfo, setProfileInfo] = React.useState(null)

  React.useEffect(() => {
    setProfileInfo(setInitialProfileInfo(props.user))
  }, []);

  const handleUpdate = (e) => {
    setProfileInfo({
      ...profileInfo,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const data = {
      phone_number_1: profileInfo.phone_number_1,
      phone_number_2: profileInfo.phone_number_2
    }
    const assignmentTable = await axios.put(editGeneralInfoURL, data, defaultAuthHeader)
    props.openSnackbar("Generelle Profilinfo wurde aktualisiert.");
  }


  if (profileInfo === null) return <></>

  return (
    <form>
      <TextField
        onChange={handleUpdate}
        variant="outlined"
        margin="normal"
        fullWidth
        id="first_name"
        label="Vorname (nicht editierbar)"
        name="first_name"
        value={profileInfo.first_name}
        InputProps={{
          readOnly: true,
        }}
      />
      <TextField
        onChange={handleUpdate}
        variant="outlined"
        margin="normal"
        fullWidth
        id="last_name"
        label="Nachname (nicht editierbar)"
        name="last_name"
        value={profileInfo.last_name}
        InputProps={{
          readOnly: true,
        }}
      />
      <TextField
        onChange={handleUpdate}
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="phone_number_1"
        label="Tel 1"
        name="phone_number_1"
        value={profileInfo.phone_number_1}
      />
      <TextField
        onChange={handleUpdate}
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="phone_number_2"
        label="Tel 2"
        name="phone_number_2"
        value={profileInfo.phone_number_2}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        onClick={handleSubmit}
      >
        Speichern
      </Button>
    </form>
  )
}

const mapStateToProps = (state) => ({
  user: state.auth.user
})

const mapDispatchToProps = {
  openSnackbar: openSnackbar
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralInfoForm)
