import React from 'react'
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  divider: {
    margin: theme.spacing(1),
  }
}));

export function Impressum(props){
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Paper variant="outlined" className={classes.paper}>
        <Typography component="h1" variant="h5">
          Impressum
        </Typography>

        <Divider className={classes.divider}/>

        <Typography variant="body2">
          Graphics und Technology GmbH
        </Typography>
        <Typography variant="body2">
          Ritterhufen 12a
        </Typography>
        <Typography variant="body2">
          14165 Berlin
        </Typography>

        <Divider className={classes.divider}/>

        <Typography variant="body2">
          Telefon: +49 30 60 79 77 90
        </Typography>
        <Typography variant="body2">
          Telefax: +49 30 692 096 069
        </Typography>
        <Typography variant="body2">
          Email: info@gtberlin.de
        </Typography>
        <Typography variant="body2">
          www.gtberlin.de
        </Typography>
      </Paper>
    </Container>
  );
}


const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Impressum);
