import React from 'react';

import { connect } from 'react-redux';
import { login } from './../../store/actions/auth';

import ResetPasswordForm  from './ResetPassword';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function SignIn(props) {
  const classes = useStyles();
  const [credentials, setCredentials] = React.useState({
    email: "",
    password: ""
  })
  const [mode, setMode] = React.useState(0)

  const handleUpdate = (e) => {
    var newCredentials = {...credentials};
    newCredentials[e.target.name] = e.target.value;
    setCredentials(newCredentials);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    var login = props.login(credentials);
  }

  const handleModeChange = (e) => {
    setMode(mode === 0 ? 1 : 0);
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        {
          mode === 0 ? <>
            <Typography component="h1" variant="h5">
              Anmelden
            </Typography>
            <form className={classes.form} onSubmit={handleSubmit}>
              <TextField
                onChange={handleUpdate}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Addresse"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                onChange={handleUpdate}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Passwort"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              {
                props.error === null ? <></> :
                <Typography
                  variant="caption"
                  display="block"
                  color="error"
                  gutterBottom
                >
                  Email oder Passwort falsch
                </Typography>
              }
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handleSubmit}
              >
                Sign In
              </Button>
            </form>
          </> : <ResetPasswordForm />
        }
        <Button color="primary" onClick={handleModeChange}>
          {
            mode === 0 ?
              <span>Passwort vergessen</span> :
              <span>Anmelden</span>
          }
        </Button>
      </div>
    </Container>
  );
}


const mapStateToProps = (state) => ({
  error: state.auth.error
})

const mapDispatchToProps = {
  login: login
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
