import * as actionTypes from '../actions/actionTypes';

const initialState = {
    open: false,
    body: ""
}

const openSnackbar = (state, action) => {
  return {
    ...state,
    open: true,
    info: action.info
  }
}

const closeSnackbar = (state, action) => {
  return {
    open: false,
    body: ""
  };
}


const snackbar = (state=initialState, action) => {
  switch (action.type) {
    case actionTypes.OPEN_SNACKBAR: return openSnackbar(state, action);
    case actionTypes.CLOSE_SNACKBAR: return closeSnackbar(state, action);
    default:
        return state;
  }
}

export default snackbar;
