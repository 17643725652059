import React from 'react';

import { connect } from 'react-redux';
import { closeSnackbar } from './../store/actions/snackbar';

import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

function SimpleSnackbar(props) {

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    props.closeSnackbar()
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={props.snackbar.open}
      autoHideDuration={6000}
      onClose={handleClose}
      message={props.snackbar.info}
      action={
        <React.Fragment>
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      }
    />
  );
}

const mapStateToProps = (state) => ({
  snackbar: state.snackbar
})

const mapDispatchToProps = {
  closeSnackbar: closeSnackbar
}

export default connect(mapStateToProps, mapDispatchToProps)(SimpleSnackbar)
