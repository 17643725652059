import React from 'react'
import axios from 'axios'

import { connect } from 'react-redux';

import { maintainerURL, actionURL } from './../../../api/urls';
import { defaultAuthHeader } from './../../../api/headers';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import Divider from '@material-ui/core/Divider';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  }
}));

const Supervisor = (props) => {
  const {status, assignment_no, updateLogs} = {...props};
  const [adminContacts, setAdminContacts] = React.useState(null)
  const [admin, setAdmin] = React.useState("");
  const handleChange = (e) => {
    setAdmin(e.target.value);
  }

  React.useEffect(() => {
    if (!adminContacts){
      loadAdminContacts();
    }
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault();
    const URL = `${actionURL}/supervise`;
    const data = {
      admin: admin,
      assignment: assignment_no
    }
    await axios.post(URL, data, defaultAuthHeader)
    updateLogs();
  }

  const loadAdminContacts = async () => {
    const response = await axios.get(maintainerURL, defaultAuthHeader)
    setAdminContacts(response.data)
  }

  if (!adminContacts) return <></>

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel id="department-label">
            Admin
          </InputLabel>
          <Select
            labelId="department-label"
            id="logChooseAdmin"
            name="admin"
            onChange={handleChange}
            label="Admin"
            value={admin}
          >
            <MenuItem value="" disabled> Auswahl </MenuItem>
            {
              adminContacts.map((admin, index) => 
                <MenuItem key={index} value={admin._id}>
                  {admin.last_name}, {admin.first_name}
                </MenuItem>
              )
            }
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          disabled={admin===""}
          onClick={handleSubmit}
        >
          {
            status === "i" ? "Auftrag Freigeben" : "Auftrag neu zuweisen"
          }
        </Button>
      </Grid>
    </Grid>
  )
  
}

const AddLog = (props) => {
  const user = props.user;
  const [action, setAction] = React.useState({
    description: "",
    duration: 0
  })

  const handleChange = (e) => {
    if (e.target.name === "description"){
      setAction({
        ...action,
        description: e.target.value
      })
    }
    else {
      setAction({
        ...action,
        duration: e.target.value
      })
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const url = `${actionURL}/create`
    const data = {
      ...action,
      assignment: props.assignment_no
    }
    await axios.post(url, data, defaultAuthHeader)
    props.updateLogs();
    setAction({
      description: "",
      duration: 0
    })
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextField
          id="log_add_description"
          name="description"
          label="Maßnahmenbeschreibung"
          multiline
          rows={4}
          variant="outlined"
          value={action.description}
          fullWidth
          onChange={handleChange}
        />
      </Grid>
      {
        !user.company.is_admin ? <></> :
        <Grid item xs={12}>
          <TextField
            id="logg_add_timeestimation"
            name="duration"
            label="Zeitwaufwand in min (optional)"
            variant="outlined"
            type="number"
            value={action.duration}
            fullWidth
            onChange={handleChange}
          />
        </Grid>
      }
      <Grid item xs={12}>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleSubmit}
      >
        Maßnahme hinzufügen
      </Button>
      </Grid>
    </Grid>
  )
}

export function Actions(props) {
  const {status, assignment_no, updateLogs} = {...props};

  const handleCancellation = async (e, mode) => {
    e.preventDefault();
    var URL = `${actionURL}/${mode}`;
    const data = {assignment: assignment_no}
    await axios.post(URL, data, defaultAuthHeader)
    updateLogs();
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Divider style={{margin: "8px"}}/>
          <AddLog 
            assignment_no={assignment_no}
            updateLogs={updateLogs}
            user={props.user}
          />
        <Divider style={{margin: "8px"}}/>
      </Grid>
      {
        !props.user.company.is_admin ? <></> : 
        <Grid item xs={12}>
          <Supervisor 
            status={status}
            assignment_no={assignment_no}
            updateLogs={updateLogs}
          />
        </Grid>
      }
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="secondary"
          onClick={(e) => handleCancellation(e, "cancel")}
          fullWidth
        >
          {
            status === "i" ? "Ablehnen" : "Stornieren"
          }
        </Button>
      </Grid>
      {
        (status === "i" && !props.user.company.is_admin) ? <></> : 
        <Grid item xs={12}>
          <Button
            color="primary"
            fullWidth
            onClick={(e) => handleCancellation(e, "close")}
          >
            Abschließen
          </Button>
        </Grid>
      }
    </Grid>
  )
}


const mapStateToProps = (state) => ({
  user: state.auth.user
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Actions);