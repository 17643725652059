import React from 'react'
import axios from 'axios';

import { actionURL } from './../../../api/urls'
import { defaultAuthHeader } from './../../../api/headers'

import Actions from './actionlog'

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';

import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  }
}));

export default function Log(props){
  const classes = useStyles();
  const [logs, setLogs] = React.useState(null);

  React.useEffect(() => {
    if (!logs && props.assignment!==null ){
      loadLogs();
    }
  }, [])

  const loadLogs = async () => {
    const url = `${actionURL}/${props.assignment.no}`
    const response = await axios.get(url, defaultAuthHeader)
    setLogs(response.data)
  }

  const updateLogs = (log) => {
    loadLogs();
    props.reloadDataOnLogUpdate()
  }

  const getHeader = (log) => {
    return <>
      {
        log.duration !== undefined && log.duration !== null && log.duration > 0 ?
        <Typography variant="caption" display="block" gutterBottom>
          Aufwand: {log.duration} min
        </Typography> :
        <></>
      }
      {log.user.last_name}, {log.user.first_name}<br/> {log.timestamp}
    </>
  }

  if (!logs && props.assignment!==null && props.assignment!==undefined){
    loadLogs();
  }
  if (!logs || props.assignment===null) return <></>

  return (
    <Container maxWidth="xl">
      <div className={classes.paper}>
        <Typography variant="h5" gutterBottom>
          Aktivitätslog
        </Typography>

        <List style={{overflow: 'auto', maxHeight: "50vh"}}>
          {logs.map((log, index) => 
            <ListItemText 
              key={index}
              primary={log.description}
              secondary={getHeader(log)}
            />
          )}
        </List>
        <Typography variant="caption" display="block" gutterBottom>
          Gesamtaufwand: {
            logs
              .filter(log => log.duration !== null & log.duration !== undefined)
              .map(log => parseInt(log.duration))
              .reduce((pv, cv) => pv + cv, 0)
          } Minuten
        </Typography>
        <Actions
          status={props.assignment.status}
          assignment_no={props.assignment.no}
          updateLogs={updateLogs}
        />
      </div>
    </Container>
  )
}
