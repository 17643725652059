import React from 'react';
import axios from 'axios';

import { connect } from 'react-redux';

import {
  companiesURL,
  departmentsURL,
  employeesURL,
  createAssignmentURL,
  createMediaURL
} from './../../../api/urls';
import { defaultAuthHeader } from './../../../api/headers';

import FileArea from './dropzone.jsx';
import ListFiles from './listFiles.jsx';

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));


export function BaseAssignment(props) {
  const classes = useStyles();
  const { assignment, handleNewAssignment } = {...props};
  const [companies, setCompanies] = React.useState(null);
  const [departments, setDepartments] = React.useState([]);
  const [customerContacts, setCustomerContacts] = React.useState([]);

  const [company, setCompany] = React.useState("");
  const [department, setDepartment] = React.useState("");
  const [customerContact, setCustomerContact] = React.useState("");
  const [files, setFiles] = React.useState([]);
  const [description, setDescription] = React.useState(assignment === null ? "" : assignment.description);
  const [showForm, setShowForm] = React.useState(false);

  React.useEffect(() => {
    loadCompanies();
  }, [])

  const loadCompanies = async () => {
    const response = await axios.get(companiesURL, defaultAuthHeader)
    setCompanies(response.data);
    if (!props.user.company.is_admin){
      setCompany(props.user.company.name_slug)
      loadDepartments(props.user.company.name_slug)

      if (props.user.rights === "u"){
        setDepartment(props.user.department.name_slug);
        await loadContacts(props.user.department.name_slug);

        setCustomerContact(props.user._id)
      }
    }
    setShowForm(true);
  }

  const loadDepartments = async (company) => {
    const url = `${departmentsURL}?company__name_slug=${company}`;
    const response = await axios.get(url, defaultAuthHeader)
    setDepartments(response.data);
  }

  const loadContacts = async (department) => {
    console.log(department)
    const url = `${employeesURL}?company__name_slug=${company}&department__name_slug=${department}`;
    const response = await axios.get(url, defaultAuthHeader)
    setCustomerContacts(response.data);
  }

  const handleChange = async (e) => {
    if (e.target.name === "company"){
      const newCompany = e.target.value
      setCompany(newCompany);
      await loadDepartments(newCompany);
    }else if (e.target.name === "department"){
      const newDepartment = e.target.value
      setDepartment(newDepartment);
      await loadContacts(newDepartment);
    }else if (e.target.name === "customerContact"){
      setCustomerContact(e.target.value)
    }else if (e.target.name === "description"){
      setDescription(e.target.value)
    }
  }

  const handleAssignmentCreation = async () => {
    let formData = new FormData()
    var customHeader = {}
    try{
      formData.append("file", files, files.name)
      customHeader = {
        headers: {
          ...defaultAuthHeader.headers,
          "Content-Type": "multipart/form-data"
        }
      }
    }
    catch {
      customHeader = {
        headers: {
          ...defaultAuthHeader.headers,
        }
      }
    }
    formData.append("company__name_slug", company)
    formData.append("department__name_slug", department)
    formData.append("customer_contact_id", customerContact)
    formData.append("description", description)
    const response = await axios.post(createAssignmentURL, formData, customHeader);
    // files.forEach(file => {
    //   var formData = new FormData();
    //   formData.append('file', file);
    //   formData.append('assignment', response.data.no);
    //   fetch(createMediaURL, {
    //     method: 'POST',
    //     body: formData,
    //     headers: {
    //       'Authorization': defaultAuthHeader.headers.Authorization
    //     },
    //   })
    // })
    handleNewAssignment(response.data);
  }

  if (!showForm) return <> </>
  return (
    <Container maxWidth="xl">
      <div className={classes.paper}>
        <Typography variant="h5" gutterBottom>
          Auftragsdetails
        </Typography>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="customer-label">
                  Kunde
                </InputLabel>
                <Select
                  labelId="customer-label"
                  id="customer"
                  name="company"
                  value={
                    assignment === null ? company : assignment.company.name_slug}
                  onChange={handleChange}
                  label="Kunde"
                  readOnly={assignment !== null || !props.user.company.is_admin}
                >
                  {
                    assignment !== null ?
                      <MenuItem value={assignment.company.name_slug}>
                        {assignment.company.name}
                      </MenuItem> : <span></span>
                  }
                  {
                    companies.map((company, index) =>
                      <MenuItem value={company.name_slug} key={index}>
                        {company.name}
                      </MenuItem>
                    )
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="department-label">
                  Abteilung
                </InputLabel>
                <Select
                  labelId="department-label"
                  id="department"
                  name="department"
                  value={assignment === null ? department : assignment.department.name_slug}
                  onChange={handleChange}
                  label="Abteilung"
                  readOnly={
                    assignment !== null
                    || company === ""
                    || (
                      props.user.rights === "u"
                      && !props.user.company.is_admin
                    )
                  }
                >
                  {
                    assignment !== null ?
                      <MenuItem value={assignment.department.name_slug}>
                        {assignment.department.name}
                      </MenuItem> : <span></span>
                  }
                  {
                    departments.map((department, index) =>
                      <MenuItem value={department.name_slug} key={index}>
                        {department.name}
                      </MenuItem>
                    )
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="contact-label">
                  Ansprechpartner (Kunde)
                </InputLabel>
                <Select
                  labelId="contact-label"
                  id="customerContact"
                  name="customerContact"
                  value={assignment === null ? customerContact : assignment.customer_contact._id}
                  onChange={handleChange}
                  label="Ansprechpartner"
                  readOnly={
                    assignment !== null
                    || department === ""
                    || (
                      props.user.rights === "u"
                      && !props.user.company.is_admin
                    )
                  }
                >
                  {
                    assignment !== null ?
                      <MenuItem value={assignment.customer_contact._id}>
                        {assignment.customer_contact.last_name}, {assignment.customer_contact.first_name}
                      </MenuItem> : <span></span>
                  }
                  {
                    customerContacts.map((customerContact, index) =>
                      <MenuItem value={customerContact._id} key={index}>
                        {customerContact.last_name}, {customerContact.first_name}
                      </MenuItem>
                    )
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                onChange={assignment !== null ? () => {return } : handleChange}
                id="description"
                name="description"
                label="Mängelbeschreibung"
                multiline
                rows={6}
                value={description}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              {
                assignment === null ? 
                <FileArea
                  setFiles={setFiles}
                /> :
                <ListFiles files={assignment.attachments} />
              }
            </Grid>
            {
              assignment === null ?
                <Grid item xs={12}>
                  <Button 
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={
                      company === ""
                      || department === ""
                      || customerContact === ""
                      || description === ""
                    }
                    onClick={handleAssignmentCreation}
                  >
                    Auftrag anlegen
                  </Button>
                </Grid> : <></>
            }
          </Grid>
        </form>
      </div>
    </Container>
  );
}


const mapStateToProps = (state) => ({
  user: state.auth.user
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(BaseAssignment);
