import React from 'react';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';


function ContactCard(props){
  const { contactType, contact } = {...props}
  return (
    <Card>
      <CardContent>
        <Typography component="h4">
          {contactType}
        </Typography>
        {
          contact === null ? <></> : (
            <>
              <Typography variant="body2" component="p">
                {contact.last_name}, {contact.first_name}
              </Typography>
              <Typography variant="caption" component="p">
                Tel 1: {contact.phone_number_1}
              </Typography>
              <Typography variant="caption" component="p">
                Tel 2: {contact.phone_number_2}
              </Typography>
              <Typography variant="caption" component="p">
                Email: {contact.email}
              </Typography>
            </>
          )
        }
      </CardContent>
    </Card>
  )
}


export default function ContactSection(props) {
  const {contactCustomer, contactAdmin} = {...props};
  return (
    <Container maxWidth="xl" style={{marginTop:"8px"}}>
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}>
        <ContactCard
          contactType="Ansprechpartner (Kunde)"
          contact={contactCustomer}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ContactCard
          contactType="Ansprechpartner (Admin)"
          contact={contactAdmin}
        />
      </Grid>
    </Grid>
    </Container>
  )
}
