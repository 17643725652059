import React, {Component} from 'react'
 
class FileArea extends Component{
  constructor(props){
    super(props);
    this.state = {
      files: []
    };
  }

  handleChange(e){
    console.log("TARGET", e.target.files[0])
    this.props.setFiles(e.target.files[0])
    this.setState({
      files: e.target.files[0]
    });
  }

  render(){
    return (
      <input
        type="file"
        id="image"
        onChange={this.handleChange.bind(this)}
      />
    )
  }
}
 
export default FileArea;
