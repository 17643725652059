import React, { Component } from 'react';
import { Route } from "react-router-dom";
import { connect } from 'react-redux';
import { checkToken } from './store/actions/auth';

import Base from './components/base/index.jsx';

import Assignments from './pages/assignments/Assignments';
import Auth from './pages/auth/Auth';
import Profile from './pages/profile/Profile';
import Impressum from './pages/legals/Impressum';
import Contacts from './pages/legals/Contacts';


class App extends Component {
  constructor(props){
    super(props);
    this.state = {
      reloadRequired: false
    }
  }

  componentDidMount(){
    if (localStorage.getItem('token') === null){
      this.setState({reloadRequired: true})
    }
    else{
      this.props.checkToken();
    }
  }

  render(){
    console.log("Open impressum")
    if (this.props.token === null){
      return <Base>
        <Route exact path="/" component={Auth} />
        <Route exact path="/impressum" component={Impressum} />
        <Route exact path="/contact" component={Contacts} />
      </Base>
    }
    if (this.state.reloadRequired === true) window.location.reload();
    return (
      <Base>
        <Route exact path="/" component={Assignments} />
        <Route exact path="/profile" component={Profile} />
        <Route exact path="/impressum" component={Impressum} />
        <Route exact path="/contact" component={Contacts} />
      </Base>
    )
  }
}


const mapStateToProps = (state) => ({
  token: state.auth.token
})

const mapDispatchToProps = {
  checkToken: checkToken
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
