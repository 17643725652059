import React from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

export default function InProgressTable(props) {
  const assignments = props.assignments;
  return (
    <TableContainer>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell>
              Auftragsnummer
            </TableCell>
            <TableCell>
              Änderungdatum
            </TableCell>
            <TableCell>
              Letzte Aktion
            </TableCell>
            <TableCell>
              Ersteller
            </TableCell>
            <TableCell>
              Admin
            </TableCell>
            <TableCell>
              Kunde
            </TableCell>
          </TableRow>
        </TableHead>


        <TableBody>
          {
            assignments.map((assignment, index) =>
              <TableRow hover role="checkbox" tabIndex={-1} key={index} onClick={() => props.handleOpenDetails(index)}>
                <TableCell>
                  {assignment.no}
                </TableCell>
                <TableCell>
                  {assignment.last_update}
                </TableCell>
                <TableCell>
                  {assignment.last_action}
                </TableCell>
                <TableCell>
                  {assignment.customer_contact.last_name}, {assignment.customer_contact.first_name}
                </TableCell>
                <TableCell>
                  {assignment.admin_contact.last_name}, {assignment.admin_contact.first_name}
                </TableCell>
                <TableCell>
                  {assignment.company.name}
                </TableCell>
              </TableRow>
            )
          }
        </TableBody>
      </Table>
    </TableContainer>
  )
}
