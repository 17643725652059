import React from 'react'
import TablePagination from '@material-ui/core/TablePagination';


export default function TablePages(props) {
  const {currentPage, handleChangePage, count} = {...props};
  return (
    <TablePagination
      rowsPerPage={parseInt(process.env.REACT_APP_ASSIGNMENT_TABLE_DEFAULT_PAGE_SIZE)}
      rowsPerPageOptions={[parseInt(process.env.REACT_APP_ASSIGNMENT_TABLE_DEFAULT_PAGE_SIZE)]}
      component="div"
      count={count}
      page={currentPage}
      onChangePage={handleChangePage}
    />
  )
}
