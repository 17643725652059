import React, { Component } from 'react';
import { connect } from 'react-redux';

import GeneralInfoForm from './GeneralInfoForm';
import EmailForm from './EmailForm';
import PasswordForm from './PasswordForm';

import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


export class Profile extends Component {
  render() {
    return (
      <div>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Allgemeine Informationen anpassen</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <GeneralInfoForm />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Email Adresse Ändern</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <EmailForm />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Passwort ändern</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <PasswordForm />
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
