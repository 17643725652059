import React, { Component } from 'react';
import axios from 'axios';

import { assignments, getAssignmentURL } from './../../api/urls';
import { defaultAuthHeader } from './../../api/headers';

import CreateNew from './CreateNew.jsx';
import DataTable from './DataTable.jsx';
import AssignmentDialog from './AssignmentDialog.jsx';
import TablePages from './tablePages.jsx';

export default class Assignments extends Component {
  constructor(props){
    super(props);
    this.state = {
      openAssignmentDialog: false,
      assignmentDialog: null,
      assignments: [],
      assignmentsLoaded: false,
      filterType: "newAssignments",
      search: "",
      page: {
        currentPage: 0
      }
    }
    this.reloadDataOnLogUpdate = this.reloadDataOnLogUpdate.bind(this);
    this.handleAssignmentDialogClose = this.handleAssignmentDialogClose.bind(this);
    this.handleAssignmentDialogOpen = this.handleAssignmentDialogOpen.bind(this);
    this.handleChangeFilterType = this.handleChangeFilterType.bind(this);
    this.handleOpenDetails = this.handleOpenDetails.bind(this);
    this.handleNewAssignment = this.handleNewAssignment.bind(this);
    this.updateSearch = this.updateSearch.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this)
  }

  handleAssignmentDialogOpen(){
    this.setState({
      ...this.state,
      openAssignmentDialog: true
    })
  }

  handleAssignmentDialogClose(){
    this.setState({
      ...this.state,
      openAssignmentDialog: false,
      assignmentDialog: null
    })
  }

  async handleChangePage(event, newPage){
    var url;
    if (newPage > this.state.page.currentPage) url = this.state.page.next;
    else url = this.state.page.previous
    const assignmentTable = await axios.get(url, defaultAuthHeader)
    this.setState({
      ...this.state,
      assignments:assignmentTable.data.results,
      page: {
        currentPage:newPage,
        totalCount: assignmentTable.data.count,
        next: assignmentTable.data.next,
        previous: assignmentTable.data.previous,
      }
    })
  }

  async handleChangeFilterType(e){
    const assignmentTable = await this.loadData(e.target.value);
    this.setState({
      ...this.state,
      assignments:assignmentTable.results,
      assignmentsLoaded:true,
      page: {
        currentPage: 0,
        totalCount: assignmentTable.count,
        next: assignmentTable.next,
        previous: assignmentTable.previous,
      },
      filterType: e.target.value,
      search: ""
    })
  }

  async reloadDataOnLogUpdate(){
    const assignmentTable = await this.loadData();
    this.setState({
      ...this.state,
      assignments:assignmentTable.results,
      assignmentsLoaded:true,
      page: {
        currentPage: 0,
        totalCount: assignmentTable.count,
        next: assignmentTable.next,
        previous: assignmentTable.previous,
      }
    })
  }

  handleOpenDetails(index){
    this.setState({
      ...this.state,
      openAssignmentDialog: true,
      assignmentDialog: this.state.assignments[index]
    })
  }

  async handleNewAssignment(assignment){
    // 
    // TODO FIX HERE
    // 
    var { assignments } = {...this.state}
    const url = `${getAssignmentURL}/${assignment.no}`
    const assignmentComplete = await axios.get(url, defaultAuthHeader)
    assignments.unshift(assignmentComplete.data);
    this.setState({
      ...this.state,
      assignments: assignments,
      assignmentDialog: assignmentComplete.data
    })
  }

  async updateSearch(e){
    const searchValue = e.target.value
    var assignmentTable;
    if (searchValue === "" || searchValue.length < 3) assignmentTable = await this.loadData();
    else assignmentTable = await this.searchData(searchValue);
    this.setState({
      ...this.state,
      search: searchValue,
      assignments:assignmentTable.results,
      assignmentsLoaded:true,
      page: {
        currentPage: 0,
        totalCount: assignmentTable.count,
        next: assignmentTable.next,
        previous: assignmentTable.previous,
      }
    })
  }

  async searchData(search){
    var url = `${assignments}?mode=search&search=${search}`;
    const response = await axios.get(url, defaultAuthHeader)
    return response.data
  }

  async loadData(filterType){
    if (filterType === undefined) filterType = this.state.filterType;
    var url = assignments;
    if (filterType === "newAssignments") url = `${url}?mode=unassigned`;
    else if (filterType === "inProgress") url = `${url}?mode=all_not_done`;
    const response = await axios.get(url, defaultAuthHeader)
    return response.data
  }

  async componentDidMount(){
    const assignmentTable = await this.loadData();
    this.setState({
      ...this.state,
      assignments:assignmentTable.results,
      assignmentsLoaded:true,
      page: {
        currentPage: 0,
        totalCount: assignmentTable.count,
        next: assignmentTable.next,
        previous: assignmentTable.previous,
      }
    })
  }

  render() {
    if (!this.state.assignmentsLoaded) return <></>
    return (
      <div>
        <CreateNew
          filterType={this.state.filterType}
          handleAssignmentDialogOpen={this.handleAssignmentDialogOpen}
          handleChangeFilterType={this.handleChangeFilterType}
          updateSearch={this.updateSearch}
        />
        <DataTable
          assignments={this.state.assignments}
          filterType={
            this.state.search === "" && this.state.search.length > 2 ?
              this.state.filterType : "searchedAssignments"
          }
          handleOpenDetails={this.handleOpenDetails}
        />
        <TablePages
          currentPage={this.state.page.currentPage}
          handleChangePage={this.handleChangePage}
          count={this.state.page.totalCount}
        />
        <AssignmentDialog
          open={this.state.openAssignmentDialog}
          handleClose={this.handleAssignmentDialogClose}
          assignmentDialog={this.state.assignmentDialog}
          handleNewAssignment={this.handleNewAssignment}
          reloadDataOnLogUpdate={this.reloadDataOnLogUpdate}
        />
      </div>
    )
  }
}
