export const BASE_URL = process.env.REACT_APP_API_URL || "https://api.ticket.esrv.info/api";
export const MEDIA_URL = process.env.REACT_APP_MEDIA_URL;


export const authVerifyToken = `${BASE_URL}/auth/authenticated`;
export const authSignIn = `${BASE_URL}/auth/token`;
export const companiesURL = `${BASE_URL}/auth/companies`;
export const departmentsURL = `${BASE_URL}/auth/departments`;
export const employeesURL = `${BASE_URL}/auth/employees`;
export const maintainerURL = `${BASE_URL}/auth/maintaineruser`;
export const resetPasswordURL = `${BASE_URL}/auth/reset-password`;

export const editGeneralInfoURL = `${BASE_URL}/auth/edit/general`;
export const editEmailURL = `${BASE_URL}/auth/edit/email`;
export const editPasswordURL = `${BASE_URL}/auth/edit/password`;

export const assignments = `${BASE_URL}/assignments/`;
export const getAssignmentURL = `${BASE_URL}/assignments/get`;
export const createAssignmentURL = `${BASE_URL}/assignments/create`;
export const createMediaURL = `${BASE_URL}/assignments/create-media`;

export const actionURL = `${BASE_URL}/assignments/actions`;
