import axios from 'axios';
import * as actionTypes from './actionTypes';
import { authVerifyToken, authSignIn } from './../../api/urls';
import {defaultAuthHeader} from './../../api/headers';

export const authStart = () => {
  return {
      type: actionTypes.AUTH_START
  }
}

export const authSuccess = (token, user) => {
  return {
      type: actionTypes.AUTH_SUCCESS,
      token: token,
      user: user
  }
}

export const logout = () => {
  localStorage.removeItem('token');
  return {
      type: actionTypes.AUTH_LOGOUT
  };
}

export const authFail = error => {
  localStorage.removeItem('token');
  return {
      type: actionTypes.AUTH_FAIL,
      error: error
  }
}

export const login = (credentials) => {
  return dispatch => {
      dispatch(authStart());
      axios.post(authSignIn, credentials)
      .then(res => {
          const token = res.data.access;
          localStorage.setItem('token', token);
          dispatch(authSuccess(token));
          window.location.reload();
      })
      .catch(err => {
        dispatch(authFail(err))
      })
  }
}

export const checkToken = () => {
  return dispatch => {
      const token = localStorage.getItem('token');
      if (token === undefined || token === null) {
          dispatch(logout());
      } else {
        axios.get(authVerifyToken, defaultAuthHeader)
        .then(res => {
          dispatch(authSuccess(token, res.data));
        })
        .catch(err => {
          dispatch(authFail())
        })
      }
  }
}
