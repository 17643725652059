import React from 'react';
import { Link as RouterLink} from 'react-router-dom';
import { connect } from 'react-redux';
import { logout } from './../../store/actions/auth';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
}));



function Nav(props) {
  const classes = useStyles();
  console.log(props.user)
  return (
    <div
      className={classes.root}
    >
      <AppBar
        position="fixed"
        color="inherit"
      >
        <Toolbar>
          <Typography
            variant="h6"
            color="inherit"
            className={classes.title}
            noWrap
          >
            <Link 
              to="/"
              component={RouterLink}
              underline="none"
              color="inherit"
            >
              I Auftragssystem Online
            </Link>
          </Typography>
          {
            props.token === null || props.user === undefined ? <></> :
            <nav>
              {
                props.user.company.is_admin ?
                  <Button color="inherit" href="http://localhost:8000/admin">
                    Admin
                  </Button> : <></>
              }
              <Button color="inherit" href="/profile">
                Profil
              </Button>
            </nav>
          }
          {
            props.token === null ? <></> : <Button color="inherit" onClick={props.logout}>Abmelden</Button>
          }
        </Toolbar>
      </AppBar>
    </div>
  );
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  user: state.auth.user
})

const mapDispatchToProps = {
  logout: logout
}

export default connect(mapStateToProps, mapDispatchToProps)(Nav);