import React from 'react'
import { connect } from 'react-redux'

import { MEDIA_URL } from './../../api/urls.js';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';


const useStyles = makeStyles((theme) => ({
  mainFeaturedPost: {
    position: 'relative',
    backgroundColor: theme.palette.grey[800],
    backgroundImage: 'url(https://source.unsplash.com/random)',
    color: theme.palette.common.white,
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(8),
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: "300px"
  },
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,.3)',
  },
  mainFeaturedPostContent: {
    position: 'relative',
    padding: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(6),
      paddingRight: 0,
    },
  },
}));


export const Thumbnail = (props) => {
  const classes = useStyles();
  const user  = props.user;
  if (user === null || user === undefined) return <></>
  return (
    <Paper
      className={classes.mainFeaturedPost}
      style={{ backgroundImage: `url(${MEDIA_URL}${user.company.thumbnail_path})` }}
    >
      {<img style={{ display: 'none' }} src={`${MEDIA_URL}${user.company.thumbnail_path}`} alt={user.company.name} />}
      <div className={classes.overlay}>
        <Grid container>
          <Grid item md={6}>
            <div className={classes.mainFeaturedPostContent}>
              <Typography component="h1" variant="h3" color="inherit" gutterBottom>
                Hey {user.first_name},
              </Typography>
              <Typography variant="h5" color="inherit" paragraph>
                Willkommen im Planungsportal!
              </Typography>
            </div>
          </Grid>
        </Grid>
      </div>
    </Paper>
  )
}

const mapStateToProps = (state) => ({
  user: state.auth.user
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(Thumbnail)
