import React from 'react'
import axios from 'axios'
import { connect } from 'react-redux'

import { editPasswordURL } from './../../api/urls';
import { defaultAuthHeader } from './../../api/headers';
import { openSnackbar } from './../../store/actions/snackbar';


import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';



const initialPassword = {
  "new_password_1": "",
  "new_password_2": "",
  "password": ""
}

export const PasswordForm = (props) => {
  const [password, setPassword] = React.useState(initialPassword)
  const [newPasswordError, setNewPasswordError] = React.useState("")
  const [responseError, setResponseError] = React.useState("")

  const handleUpdate = (e) => {
    setPassword({
      ...password,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (password.new_password_1 !== password.new_password_2){
      setNewPasswordError("Passwörter stimmen nicht überein.")
      setResponseError("")
      setPassword(initialPassword)
      return
    }

    const data = {
      new_password: password.new_password_1,
      password: password.password
    }

    try {
      const assignmentTable = await axios.put(editPasswordURL, data, defaultAuthHeader)
      setResponseError("")
      setNewPasswordError("")
      props.openSnackbar("Das Passwort wurde aktualisiert.");
    }
    catch (err) {
      setNewPasswordError("")
      setResponseError("Falsche Passworteingabe")
    }
    setPassword(initialPassword)
  }

  return (
    <form>
      <TextField
        onChange={handleUpdate}
        variant="outlined"
        margin="normal"
        error={newPasswordError !== ""}
        required
        fullWidth
        type="password"
        id="new_password_1"
        label="Neues Passwort"
        name="new_password_1"
        value={password.new_password_1}
        helperText={newPasswordError}
      />
      <TextField
        onChange={handleUpdate}
        variant="outlined"
        margin="normal"
        error={newPasswordError !== ""}
        required
        fullWidth
        type="password"
        id="new_password_2"
        label="Neues Passwort wiederholen"
        name="new_password_2"
        value={password.new_password_2}
        helperText={newPasswordError}
      />
      <TextField
        onChange={handleUpdate}
        variant="outlined"
        margin="normal"
        error={responseError !== ""}
        required
        fullWidth
        type="password"
        id="password"
        label="Aktuelles Passwort"
        name="password"
        helperText={responseError}
        value={password.password}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        onClick={handleSubmit}
      >
        Speichern
      </Button>
    </form>
  )
}

const mapStateToProps = (state) => ({
  user: state.auth.user
})

const mapDispatchToProps = {
  openSnackbar: openSnackbar
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordForm)
